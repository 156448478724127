.clinsys-screens {
  flex: 1;
  display: flex;
  width: 100%;
  margin-top: 60px;
  justify-content: center;
}
.cards-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  
