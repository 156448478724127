.contact{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}
.contact-title {
  color: black;
  font-weight: 200;
  font-size: 1.6em;
  margin: 30px;
}
.contact-form {
  display: flex;
  align-items: center;
  text-align-last: left;
  /* text-align: left; */
  justify-content: center;
  background: var(--green-2);
  border: 2px solid white;
  border-radius: 20px;
  justify-content: center;
  flex-direction: column;
  margin: 40px;
  padding: 50px;
  padding-top: 0;
  padding-bottom: 100px;
}
.clinsys-logo{
  align-items: center;
  height: 126px;
  width: 301px;
  background-size: contain;
}
.contact-text{
  align-items: left;
  /* height: 126px;
  width: 301px; */
  background-size: contain;
}
.space-filler{
  align-items: center;
  /* height: 126px;
  width: 301px; */
  background-color: white;
  min-height:8vw;
  width: 100%;
}