.banner{
  flex: 1;
  display: flex;
  background: var(--green-2);
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-bottom: 40px;
  min-height: 160px;
  margin-top: 50px;
  flex-direction: column;
}

.banner-title{
  color: white;
  font-size: 30px;
  max-width: 100vmin;
  padding: 10px;;
}

.content-container{
  max-width: 120vmin;
}