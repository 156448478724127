.section{
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 10vw;
  margin-right: 10vw;
}

.noImage{
  flex: 1;
  border-radius: 1em;
  flex-direction: column;
  display: flex;
  padding: 40px;
  justify-content: space-evenly;
  margin: 10px;
}

.text-container {
  flex: 1;
  border-radius: 1em;
  flex-direction: column;
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  margin: 10px;
  max-width: 550px;
  align-items: center;
}

.title {
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.list-container {
  max-width: 120vmin;
  /* max-width: 500px; */
}

.medical-image{
  max-width: 650px;
  /* min-width: 400px; */
  margin: 10px;
}
