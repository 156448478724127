.body {
  margin-top: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  flex-grow: 1;
  flex-wrap: wrap;
  margin-bottom: 50px;
  /* color: white; */
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
  font-family: arial, helvetica, verdana, "Yu Gothic", sans-serif;
  margin: 0;
}
/* 
@font-face {
  font-family: 'Arial Rounded MT Bold';
  font-style: italic;
  font-weight: bold;
  src: local('Arial Rounded MT Bold'), url('MYRIADPRO-REGULAR.woff') format('woff');
} */

html {
  --color-text: black;
  --color-background: green;
  --color-white: white;
  --color-primary: white;
  --color-list-even: #84C09F;
  --color-list-odd: white;
  --color-secondary: greenyellow;
  
  /* Branding Design Palette */
  --blue-1: #0E837F;
  --blue-2: #0e6c83;
  --blue-3: #184783;
  --green-1: #008000;
  --green-2: #239555;
  --green-3: #84C09F;
  
  --font-size-small: 16px;
  --font-size-medium: 22px;
  --gutter: 16px;
  @media (min-width: 1024px) {
    --font-size-small: 21px;
    --font-size-medium: 24px;
    --gutter: 12px;
  }
}

.app{
  width: 100%;
}

body{
  color: var(--color-white);
  background: var(--color-white);
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li{
  margin: 2px 15px 5px 30px;
}

p{
  margin: 2px 15px 5px 15px;
  white-space:pre-wrap;
}

.margin-sides{
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}

.wrapper{
  width: 100%;
  margin: 0 auto;
}

.containerFlex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.containerBlock{
  display: block;
  margin: 2px auto;
  width: 100%;
  text-align: center;
}

.filler{
  flex: 1;
  display: flex;
  width: 100%;
  margin-top: 60px;
}

.flexGrow{
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
}

/* ******************** Main Content ************************ */

.homeWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 50px;
}

.phone1 {
  height: 464px;
  width: 218px;
}

.phone2 {
  height: 448px;
  width: 217px;
}

.main-wrapper{
  padding: 10px 5px;
}

.main{
  flex: 5;
  background: var(--green-2);
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  border-radius: 20px;
  padding: 15px;
  padding-bottom: 20px;
  margin: 10px;
  border: 2px solid white;
}

.main-border{
  flex: 5;
  background: var(--green-3);
  text-align: center;
  margin-bottom: 1;
  max-width: 600px;
  border-radius: 30px;
  border: 12px solid var(--blue-1);
  margin: 10px;
  margin-bottom: 60px;
}

.main li {
  padding: 10px;
}

.description{
  display: block;
  color: #fff;
  font-size: 14px;
}

.list-left{
  text-align: left;
}

.list-left li{
  margin: 1px;
  padding: 1px;
  padding-left: 30px;
}

.radioTop{
  margin-top: 10px;
  box-shadow: 2px 2px 2px 2px #ccc;
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}
