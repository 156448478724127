.container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: space-around;
    justify-content: center;
  }

  .phone {
    flex: 1;
    height: 464px;
    width: 218px;
    margin-right: 50px;
    margin-left: 50px;
    align-content: center;
  }

  .row {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .covidApp-body{
    flex: 1;
    display: flex;
    flex-direction: column;
    background: var(--blue-2);
    text-align: center;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 1em;
    width: 50%;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .covidApp-text {
    font-size: 1em;
    color: black;
  }
  
  .CheckPoint-text {
    background-color:cornsilk;
    font-size: 1.2em;
    /* font-family: 'Arial Rounded MT Bold'; */
    /* font-style: italic; */
    /* font-style:italic; */
    color:black;
    font-weight: bold;
  }
  .Covid-button{
    border: 2px ridge white;
    transition: all ease 0.1s;
    background-color:var(--blue-1);
    border-radius: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .Covid-a{
    text-decoration: none;
    color: white;
  }