.contact-illustration{
  display: flex;
  height: 503px;
  width: 100%;
  background-image: url(../Assets/MedicalBackground.jpg);
  background-size:contain;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
