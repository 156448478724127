.software{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  left: 0;
  right: 0;
}
.align-center{
  align-items: center;
  justify-content: center;
}
.header-text{
  flex: 1;
  display: flex;
  color: black;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.bullet-points{
  display: flex;
  flex-direction: column;
  margin: 0.4em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.li{
  font-size: 0.3em;
  text-align: left;
}
.software-cards{
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty{
  list-style: none;
}
.medical-slice{
  height: 100%;
  width: 100%;
  image-rendering: contain;
  margin-top: 30px;
  margin-bottom: 30px;
}
.blue-border{
  border-top: 12px solid #82c4bd;
  border-bottom: 12px solid #82c4bd;
}
.darkBlue-border{
  border-top: 12px solid #376180;
  border-bottom: 12px solid #376180;
}
.green-border{
  border-top: 12px solid #82c48a;
  border-bottom: 12px solid #82c48a;
}
.reports-image{
  flex: 1;
  display: flex;
  align-self: center;
  justify-content: center;
  height:550px;
  margin-top: 30px;
  margin-bottom: 30px;
}