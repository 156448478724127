.nav-wrapper{
    background: var(--green-1);
    width: 100%;
    height: 62px;
    z-index: 1;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  } 
  .nav-logo{
    margin-left:30px;
    margin-top: 2px;
  }
  .nav-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-right: 20px;
  }
  nav li{
    list-style-type: none;
    margin-top: 5px;
    text-align: center;
  }
  nav a{
    text-decoration: none;
    text-align: center;
    color: #fff;
    padding: 6px;
    margin: 0 auto;
    flex-basis: 20%;
    font-size: 20px;
  }
  a:hover{
    color: white;
  }
  nav a.active{
    border: 2px ridge white;
    transition: all ease 0.1s;
    background-color:var(--blue-1);
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
  }