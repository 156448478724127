.card{
  display: flex;
  flex-direction: column;
  background: var(--green-2);
  text-align: center;
  align-items: center;
  padding: 20px;
  margin-top: 50px;
  margin-right: 15px;
  margin-left: 15px;
  max-width: 32vmin;
  /* height: 43vmin; */
  height: 420px;
  border-radius: 1em;
  padding-bottom: 2vw;
}

.icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  margin: 0;
}

.cardText-container {
  flex-direction: column;
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
}

.title {
  color: white;
  font-size: 1em;
  font-weight: bold;
  margin-top: 1vw;
}

.subtitle {
  color: white;
  font-size: 0.8em;
  margin-top: 1vw;
}
