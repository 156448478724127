.footer {
    /* position: sticky;
    bottom: 0px; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--green-1);
    height: 60px;
    width: '100%';
    margin-top: 2em;
  }
  
  .footer-link{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-right: 60px;
  }

  footer li{
    list-style-type: none;
    text-align: centre;
    display: inline;
  }
  
  footer a{
    text-decoration: none;
    text-align: center;
    color: #fff;
  }